import styled, {
  css,
  device,
  typography,
  space,
  themeGet,
  layout,
} from "@styled";
import { allowedTags } from "@utils";

export const StyledSectionTitle = styled("div").withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ![...allowedTags].includes(prop) && defaultValidatorFn(prop),
})`
  ${typography};
  ${space};
  ${layout}

  margin-bottom: 30px;
  ${device.xlarge} {
    margin-bottom: 50px;
  }
  ${device.xxlarge} {
    margin-bottom: 70px;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  border-left: 3px solid #fa4f19;
  padding-left: 10px;
  line-height: 1.2;
  ${device.medium} {
    font-size: 30px;
    border-left: 5px solid #fa4f19;
    padding-left: 20px;
  }
  ${device.xlarge} {
    font-size: 35px;
  }
  ${device.xxlarge} {
    font-size: 40px;
  }
  ${({ $layout }) =>
    $layout === 2 &&
    css`
      margin-block-end: 10px;
      ${device.medium} {
        margin-block-end: 20px;
      }
    `}
  span {
    color: ${themeGet("colors.primary")};
  }
`;
export const StyledSubheading = styled.h4`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  color:#fff;
  margin-top:25px;
  letter-spacing: 0.5px;
  ${device.medium} {
    margin-top: 20px;
    font-size: 40px;
  }
  ${device.xlarge} {
    font-size: 24px;
    margin-top:60px;
  }
`;
export const StyledSubtitle = styled.h6`
  color: ${themeGet("colors.primary")};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 24px;
  ${({ $layout }) =>
    $layout === 1 &&
    css`
      margin-block-start: -5px;
      margin-block-end: 10px;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 3px;
      line-height: 1.43;
      ${device.medium} {
        margin-block-end: 20px;
      }
    `}
  ${({ $layout }) =>
    $layout === 2 &&
    css`
      font-size: 20px;
    `}
`;

export const StyledDesc = styled.p`
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.4;
  ${device.medium} {
    margin-top: 30px;
    font-size: 18px;
    line-height: 1.4;
  }
  ${device.xlarge} {
    font-size: 20px;
    line-height: 1.5;
  }
  ${device.xxlarge} {
    font-size: 22px;
    line-height: 1.6;
  }
  ${space}
  a {
    color: #f44f1a;
    font-size: 18px;
  }
`;
