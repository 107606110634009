import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
import {
  StyledSectionTitle,
  StyledTitle,
  StyledSubtitle,
  StyledDesc,
  StyledSubheading,
} from "./style";

const SectionTitle = ({
  title,
  subtitle,
  description,
  layout,
  subheading,
  className,
  descProps,
  ...props
}) => {
  if (layout === 1) {
    return (
      <StyledSectionTitle
        className={cn(className, "section-title")}
        $layout={layout}
        {...props}
      >
        {subtitle && (
          <StyledSubtitle
            $layout={layout}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        {title && (
          <StyledTitle
            $layout={layout}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
         {subheading && (
          <StyledSubheading
            $layout={layout}
            dangerouslySetInnerHTML={{ __html: subheading }}
          />
        )}
        {description && (
          <StyledDesc
            {...descProps}
            dangerouslySetInnerHTML={{ __html: description }}
          ></StyledDesc>
        )}
      </StyledSectionTitle>
    );
  }
  return (
    <StyledSectionTitle
      className={cn(className, "section-title")}
      $layout={layout}
      {...props}
    >
      {title && (
        <StyledTitle
          $layout={layout}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && <StyledSubtitle $layout={layout}>{subtitle}</StyledSubtitle>}
      {subheading && <StyledSubheading $layout={layout}>{subheading}</StyledSubheading>}
      {description && <StyledDesc {...descProps}>{description}</StyledDesc>}
    </StyledSectionTitle>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  layout: PropTypes.number,
  subheading: PropTypes.string,
  className: PropTypes.string,
  descProps: PropTypes.shape({}),
};

SectionTitle.defaultProps = {
  layout: 1,
  textAlign: "left",
};
export default SectionTitle;
